'use strict';

angular.module('informaApp')
    .directive('infCompanyProfile', ["$location", "$timeout", "ModalHelper", "CompanyProfileService", "CompanyProfileStore", "NavigatorService", "DiseaseNameHelper", "BiomedtrackerUrlBuilder",
        "HashService", "ChartElementsHelper",
        function ($location, $timeout, ModalHelper, CompanyProfileService, CompanyProfileStore, NavigatorService, DiseaseNameHelper, BiomedtrackerUrlBuilder, HashService, ChartElementsHelper) {
            return {
                restrict: 'E',
                templateUrl: 'directives/inf-company-profile/template.ptl.html',
                scope: {
                    data: "=",
                    onEmpty: "=",
                    modalId: "=",
                    startDate: '=',
                    endDate: '='
                },
                sessionKey: "company.profile.tabs",
                saveTab: function (scope, hash) {
                    CompanyProfileStore.saveCompanyProfile({
                        companyName: scope.companyName,
                        companyId: scope.companyId,
                        companyUrl: scope.companyUrl,
                        diseaseGroups: scope.diseaseGroups,
                        drugClassGroups: scope.drugClassGroups,
                        originalData: scope.originalData,
                        activePhase: scope.activePhase,
                        hash: hash
                    });

                    scope.activeProfileId = scope.companyId;
                },
                mapDataForPieChart: function (data, phaseName, success) {
                    var result =  _.filter(data, function (x) {
                        return  x[phaseName].success !== 0 ||  x[phaseName].failed !== 0;
                    })

                    return result.map(function (x) {
                        return [DiseaseNameHelper.breakWord(x.name), success ? x[phaseName].success : x[phaseName].failed];
                    });
                },
                closeTab: function (scope, companyId) {
                    CompanyProfileStore.removeProfile(companyId);

                    if (CompanyProfileStore.count() > 0){
                        scope.activeProfileId = 0;
                    } else {
                        if (scope.onEmpty){
                            scope.onEmpty();
                        }
                    }
                },
                getTabs: function () {
                    return CompanyProfileStore.getAllCompanyProfiles();
                },
                setProfileData: function (scope, profile) {
                    scope.companyId = profile.companyId;
                    scope.companyName = profile.companyName;
                    scope.companyUrl = profile.companyUrl;
                    scope.diseaseGroups = profile.diseaseGroups;
                    scope.drugClassGroups = profile.drugClassGroups;
                    scope.originalData = profile.originalData;
                    scope.activePhase = profile.activePhase;
                    scope.hash = profile.hash;

                    scope.colorPattern = {
                        diseaseGroups: this.getColorPattern(scope.originalData.all.diseaseGroups, scope.originalData.diseaseGroups),
                        drugClassGroups: this.getColorPattern(scope.originalData.all.drugClassGroups, scope.originalData.drugClassGroups),
                    };
                },
                getColorPattern: function (allData, data) {
                    var matches = this.getMatchesForAllData(allData);

                    var result = {};

                    for (var i = 0; i < data.length; i++){
                        var index = matches.find(function (x) {
                            return x.id === data[i].id;
                        }).index;

                        result[data[i].name] = ChartElementsHelper.colors[index % ChartElementsHelper.colors.length];
                    }

                    return result;
                },
                getMatchesForAllData: function (data) {
                    return data
                        .map(function (x) { return x.id; })
                        .sort()
                        .map(function (x, i) {
                            return {
                                id: x,
                                index: i
                            };
                        });
                },
                loadProfile: function (scope) {
                    if (CompanyProfileStore.count() > 0){
                        var profile = scope.activeProfileId == null
                            ? CompanyProfileStore.getAllCompanyProfiles()[0]
                            : CompanyProfileStore.getCompanyProfileById(scope.activeProfileId);

                        scope.activeProfileId = profile.companyId;
                        CompanyProfileStore.setActiveCompanyId(scope.activeProfileId)
                        this.setProfileData(scope, profile);
                    }
                },
                processData: function (scope, data, companyId, companyName) {
                    this.setProfileData(scope, {
                        companyId: companyId,
                        companyName: companyName,
                        companyUrl: BiomedtrackerUrlBuilder.buildCompanyUrl(companyId),
                        diseaseGroups: {
                            left: this.mapDataForPieChart(data.diseaseGroups, scope.activePhase.propName, true),
                            right: this.mapDataForPieChart(data.diseaseGroups, scope.activePhase.propName, false),
                        },
                        drugClassGroups: {
                            left: this.mapDataForPieChart(data.drugClassGroups, scope.activePhase.propName, true),
                            right: this.mapDataForPieChart(data.drugClassGroups, scope.activePhase.propName, false),
                        },
                        originalData: scope.originalData,
                        activePhase: scope.activePhase,
                        hash: scope.hash
                    });

                    scope.activeProfileId = companyId;
                    CompanyProfileStore.setActiveCompanyId(scope.activeProfileId)
                },
                getSourceFromServer: function (scope, drugIndicationsIds) {
                    var _this = this;

                    var modal = "#" + scope.modalId;

                    ModalHelper.showLoadingModal(modal, function () {
                        scope.loading = true;

                        CompanyProfileService.getCompanyProfile(drugIndicationsIds, scope.startDate, scope.endDate).then(function (response) {
                            var data = response.data.data;

                            scope.originalData = data;
                            _this.processData(scope, data, scope.data.companyId, scope.data.companyName);

                            _this.saveTab(scope, scope.hash);
                            scope.tabs = _this.getTabs();
                        });
                    });
                },
                getDrugIndicationHash: function (drugIndicationsIds) {
                    return HashService.getHashFromIntArray(drugIndicationsIds);
                },
                link: function (scope, element, attr) {
                    if (!scope.data || !scope.data.drugIndicationsIds.shown){
                        NavigatorService.openTable();
                        return;
                    }

                    scope.loading = true;

                    var _this = this;

                    scope.tabs = this.getTabs();
                    scope.activeProfileId = CompanyProfileStore.getActiveCompanyId();

                    scope.phases = [
                        { name: "Phase I", propName: "phase1" },
                        { name: "Phase II", propName: "phase2" },
                        { name: "Phase III", propName: "phase3" },
                        { name: "NDA/BLA", propName: "ndabla" },
                    ];

                    scope.activePhase = scope.phases[0];

                    scope.togglePhase = function (x) {
                        scope.activePhase = x;

                        _this.processData(scope, scope.originalData, scope.companyId, scope.companyName);
                        _this.saveTab(scope, scope.hash)
                    }

                    scope.$watch("data", function (newValue) {
                        if (newValue){
                            var phaseIsNotNull = newValue.phase != null;

                            scope.hash = _this.getDrugIndicationHash(newValue.drugIndicationsIds.shown);
                            var id = CompanyProfileStore.getCompanyIdByHash(scope.hash);

                            if ((id == null && !phaseIsNotNull && scope.activeProfileId != null) || id != null){
                                scope.activeProfileId = phaseIsNotNull ? id : scope.activeProfileId;

                                _this.loadProfile(scope);

                                if (phaseIsNotNull){
                                    scope.togglePhase(scope.phases[newValue.phase]);
                                }
                            } else {
                                scope.activePhase = scope.phases[newValue.phase];
                                _this.getSourceFromServer(scope, newValue.drugIndicationsIds.shown);
                            }
                        }
                    });

                    scope.closeTab = function (companyId) {
                        _this.closeTab(scope, companyId);

                        scope.activeProfileId = null;
                        _this.loadProfile(scope);

                        scope.tabs = _this.getTabs();
                    }

                    scope.changeTab = function (companyId) {
                        scope.activeProfileId = companyId;

                        $timeout(function () {
                            _this.loadProfile(scope);
                        })
                    }

                    scope.renderingComplete = function () {
                        scope.loading = false;
                        ModalHelper.hideModal("#" + scope.modalId)
                    }
                }
            }
        }]);
